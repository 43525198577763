import React, {useEffect, useRef, useState } from "react";
import VCF from "vcf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IVcardProps {
  handleVcardChange: (base64File: string) => void;
  value: string | null;
}

const Vcard: React.FC<IVcardProps> = ({
  handleVcardChange,
  value,
}: IVcardProps) => {
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState<number>(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [birthDay, setBirthDay] = useState<Date>();
  const [address, setAddress] = useState("");
  const [phones, setPhones] = useState([""]);
  const [emails, setEmails] = useState([""]);
  const [websites, setWebsites] = useState([""]);

  const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)
  const uploadImageRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const savedFileName = localStorage.getItem('fileName');
    const savedFileSize = localStorage.getItem('fileSize');
    if (savedFileName) setFileName(savedFileName);
    if (savedFileSize) setFileSize(parseInt(savedFileSize, 10));
  }, [])

  useEffect(() => {
    const vCard = new VCF();
    const stringBirthDay =
      birthDay &&
      `${birthDay.getFullYear()}-${
        birthDay.getMonth() + 1
      }-${birthDay.getDate()}`;
    vCard.version = "3.0";
    vCard.set("fn", `${firstName} ${lastName}`, { charset: "utf-8" });
    vCard.set("n", `${lastName};${firstName};;;`, { charset: "utf-8" });
    company && vCard.set("org", company, { charset: "utf-8" });
    job && vCard.set("title", job, { charset: "utf-8" });
    birthDay && vCard.set("bday", stringBirthDay, { charset: "utf-8" });
    address && vCard.set("adr", address, { charset: "utf-8" });
    phones.forEach((phone) => {
      phone && vCard.add("tel", phone, { charset: "utf-8" });
    });
    emails.forEach((email) => {
      email && vCard.add("email", email, { charset: "utf-8" });
    });
    websites.forEach((url) => {
      url && vCard.add("url", url, { charset: "utf-8" });
    });

    if (photo && file) {
      const mimeType = `image/${file.type}` || "image/jpeg";
      vCard.add('photo', photo,  { type: mimeType, encoding: "BASE64"});
      setFileName(file.name)
      setFileSize(file.size)
    }else if(photo && !file){
      vCard.add('photo', photo, { encoding: "BASE64"});
    }

    const vCardString = vCard.toString();
    const base64VCard = btoa(unescape(encodeURIComponent(vCardString)));
    const dataUrl = `data:text/vcard;base64,${base64VCard}`;

    handleVcardChange(dataUrl);
    // eslint-disable-next-line
  }, [
    firstName,
    lastName,
    company,
    job,
    birthDay,
    address,
    phones,
    emails,
    websites,
    photo, 
    file
  ]);

  useEffect(() => {
    if (!value) return;
    const fetchAndParseVCard = async () => {
      try {
        const response = await fetch(value);
        if (!response.ok) {
          throw new Error(`Failed to fetch vCard: ${response.statusText}`);
        }
        const vCardString = await response.text();
        const vCard = new VCF().parse(vCardString);
        const nField = vCard.get("n");
        let firstName = "";
        let lastName = "";
        if (nField) {
          const nValue = String(nField.valueOf());
          [lastName, firstName] = nValue.split(";");
        }
        setFirstName(firstName);
        setLastName(lastName);

        setCompany((vCard.get("org")?.valueOf() as string) || "");
        setJob((vCard.get("title")?.valueOf() as string) || "");
        vCard.get("bday")?.valueOf() &&
          setBirthDay(new Date(vCard.get("bday")?.valueOf() as string));
        setAddress((vCard.get("adr")?.valueOf() as string) || "");

        const tel = vCard.get("tel");
        if (Array.isArray(tel)) {
          setPhones(tel.map((telField) => String(telField.valueOf())));
        } else if (typeof tel === "object") {
          setPhones([String(tel.valueOf())]);
        }

        const email = vCard.get("email");
        if (Array.isArray(email)) {
          setEmails(email.map((emailField) => String(emailField.valueOf())));
        } else if (typeof email === "object") {
          setEmails([String(email.valueOf())]);
        }

        const url = vCard.get("url");
        if (Array.isArray(url)) {
          setWebsites(url.map((urlField) => String(urlField.valueOf())));
        } else if (typeof url === "object") {
          setWebsites([String(url.valueOf())]);
        }
        setPhoto((vCard.get("photo")?.valueOf() as string) || "");
      } catch (error) {
        console.error("Error fetching or parsing vCard:", error);
      }
    };

    fetchAndParseVCard();
  }, [value]);

  const changeBirthDay = (date: any) => {
    setBirthDay(date);
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const base64Photo = reader.result.toString().split(",")[1];
          setPhoto(base64Photo);
        }
      };
      reader.readAsDataURL(file);
      localStorage.setItem('fileName', file.name);
      localStorage.setItem('fileSize', file.size.toString());
    }
  }, [file])

  const clickToUploadInput = () => {
    if(uploadImageRef.current){
      uploadImageRef.current.click()
    }
  }

  return (
    <>
      <div className="form-floating mb-3">
      {photo ?
        <>
          <div
              className={`${isAdvancedMode ? 'mb-3' : ''} btn btn-outline-primary bg-btn-img-color w-100`}>
              <div className='d-flex align-items-center justify-content-between h-100'>
                  <div className='col-9 d-flex align-items-center h-100'>
                      {photo ? (
                          <div
                              className='d-flex align-items-center justify-content-center h-100 overflow-hidden rounded-1'
                              style={{width: '37px'}}>
                              <img width={57} src={`data:image/png;base64,${photo}`} alt=''/>
                          </div>
                      ) : (
                          <></>
                      )}
                      {
                          <div className={`text-start ps-2 lh-sm`}>
                              <p className='mb-0 text-dark'>
                                  {fileName ? fileName : "File Name"}
                              </p>
                              <p className='mb-0 text-secondary fs-7'><small>
                                  {fileSize ? (+fileSize / 1048576).toFixed(3) + " MB" : "Size"}
                              </small>
                              </p>
                          </div>
                      }
                  </div>
                  <div className='d-flex align-items-center'>
                      <div onClick={() => setPhoto("")}><i className="bi bi-x-circle text-dark"></i></div>
                  </div>
              </div>
          </div>
          </>
          :
          <>
          <div className={'btn btn-outline-primary bg-btn-img-color text-primary w-100 p-3 mb-1'}
               onClick={clickToUploadInput}>
              <i className='bi bi-download me-2'></i>Upload Image</div>
          <p className={`${isAdvancedMode ? '' : 'mb-0'} text-secondary fs-7`}>Support file formats: PNG, JPEG.</p>
          </>
        }
      </div>
      <input ref={uploadImageRef} onChange={(e) => {setFile(e.target.files[0])}} className="d-none" type="file" accept="image/*"/>
      <div className="form-floating mb-3">
        <input
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          placeholder={"Set First Name"}
          value={firstName}
          className={"form-control"}
        />
        <label htmlFor="edit-block-modal-href-input" className="form-label">
          First Name
        </label>
      </div>
      <div className="form-floating mb-3">
        <input
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          placeholder={"Set Last Name"}
          value={lastName}
          className={"form-control"}
        />
        <label htmlFor="edit-block-modal-href-input" className="form-label">
          Last Name
        </label>
      </div>
      <div className="form-floating mb-3">
        <input
          onChange={(e) => setCompany(e.target.value)}
          type="text"
          placeholder={"Set Company Name"}
          value={company}
          className={"form-control"}
        />
        <label htmlFor="edit-block-modal-href-input" className="form-label">
          Company Name
        </label>
      </div>
      <div className="form-floating mb-3">
        <input
          onChange={(e) => setJob(e.target.value)}
          type="text"
          placeholder={"Set Job Title"}
          value={job}
          className={"form-control"}
        />
        <label htmlFor="edit-block-modal-href-input" className="form-label">
          Job Title
        </label>
      </div>
      <div className="form-floating mb-3">
        <DatePicker
          style={{ width: "100%" }}
          className="form-control date-picker"
          selected={birthDay}
          onChange={changeBirthDay}
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
        />
        <label
          htmlFor="edit-block-modal-href-input"
          className="form-label date-label"
        >
          Date of birth
        </label>
      </div>

      {phones.map((item, index) => {
        return (
          <div className="form-floating mb-3 position-relative">
            <input
              onChange={(e) =>
                setPhones(
                  phones.map((el, i) => (i === index ? e.target.value : el))
                )
              }
              type="text"
              placeholder={"Set Phone number"}
              value={item}
              className={"form-control w-100"}
            />
            <label htmlFor="edit-block-modal-href-input" className="form-label">
              Phone number
            </label>
            <div
              className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
              onClick={() => {
                if (index === 0) {
                  setPhones([...phones, ""]);
                } else {
                  setPhones(phones.filter((_, i) => i !== index));
                }
              }}
            >
              {index === 0 ? (
                phones.length <= 4 ? (
                  <i className="bi bi-plus-circle me-1 mb-0" />
                ) : null
              ) : (
                <i className="bi bi-dash-circle me-1 mb-0" />
              )}
            </div>
          </div>
        );
      })}

      {emails.map((item, index) => {
        return (
          <div className="form-floating mb-3 position-relative">
            <input
              onChange={(e) =>
                setEmails(
                  emails.map((el, i) => (i === index ? e.target.value : el))
                )
              }
              type="text"
              placeholder={"Set Email"}
              value={item}
              className={"form-control w-100"}
            />
            <label htmlFor="edit-block-modal-href-input" className="form-label">
              Email
            </label>
            <div
              className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
              onClick={() => {
                if (index === 0) {
                  setEmails([...emails, ""]);
                } else {
                  setEmails(emails.filter((_, i) => i !== index));
                }
              }}
            >
              {index === 0 ? (
                emails.length <= 4 ? (
                  <i className="bi bi-plus-circle me-1 mb-0" />
                ) : null
              ) : (
                <i className="bi bi-dash-circle me-1 mb-0" />
              )}
            </div>
          </div>
        );
      })}

      {websites.map((item, index) => {
        return (
          <div className="form-floating mb-3 position-relative">
            <input
              onChange={(e) =>
                setWebsites(
                  websites.map((el, i) => (i === index ? e.target.value : el))
                )
              }
              type="text"
              placeholder={"Set Website"}
              value={item}
              className={"form-control w-100"}
            />
            <label htmlFor="edit-block-modal-href-input" className="form-label">
              Website
            </label>
            <div
              className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
              onClick={() => {
                if (index === 0) {
                  setWebsites([...websites, ""]);
                } else {
                  setWebsites(websites.filter((_, i) => i !== index));
                }
              }}
            >
              {index === 0 ? (
                websites.length <= 4 ? (
                  <i className="bi bi-plus-circle me-1 mb-0" />
                ) : null
              ) : (
                <i className="bi bi-dash-circle me-1 mb-0" />
              )}
            </div>
          </div>
        );
      })}

      <div className="form-floating mb-3">
        <input
          onChange={(e) => setAddress(e.target.value)}
          type="text"
          placeholder={"Set Address"}
          value={address}
          className={"form-control"}
        />
        <label htmlFor="edit-block-modal-href-input" className="form-label">
          Address
        </label>
      </div>
    </>
  );
};

export default Vcard;
