import React from "react";

interface IncomeProperties {
    filePath: string;
    size: string,
    onClick?: () => void
    onRemoveFile?: () => void
    fileName: string
}

const UploadedFilePreview: React.FC<IncomeProperties> = ({filePath, size, onClick, onRemoveFile, fileName}: IncomeProperties) => {


    const getPreview = (filePath: string) => {
        if (filePath.indexOf('.jpg') || filePath.indexOf('.png')) {
            return filePath;
        } else {
            return '/logo192.png'
        }
    }

    return <>
        <div className='d-flex align-items-center bg-opacity-10 bg-success border border-success p-2 cursor-pointer'
             onClick={onClick && onClick}
             style={{borderRadius: '4px'}}>
            <div className='row'>
                <div className='col-2 '>
                    <img className='img-fluid' src={getPreview(filePath)} alt='' style={{height: ''}}/>
                </div>
                <div className={'col-8 col-md-9 text-truncate'}>
                    <div className={`text-start ps-2`}>
                                        <span className='text-decoration-none text-dark fs-5 text-truncate'>
                                            {fileName}
                                        </span>
                        <br/>
                        <span className='text-decoration-none text-secondary fs-6'>
                                            {size} MB
                                        </span>
                    </div>
                </div>
                <div className='col-2 col-md-1'>
                    <i className="bi bi-x-circle text-dark fs-4" onClick={(event) => {
                        event.stopPropagation();
                        onRemoveFile && onRemoveFile()
                    }}></i>
                </div>
            </div>
        </div>
    </>
}
export default UploadedFilePreview;