import React, {useEffect, useState} from "react";
import {Modal, Tooltip} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addNode, deleteNode} from "../../reducers/nodes";
import {
    changeHiddenMobile,
    editBarHotkeys,
    handlerCopy,
    moveBackAction,
    moveForwardAction,
    parentExistsFunc,
    parentPlusButtonClick,
    pasteCopyNode,
} from "../../service/EditBarService"
import {changeActiveModalNode} from "../../reducers/node-modal";
import ExportNode from "./ExportNode";
import DragCornerMobile from "../context-menu/block/modules/DragCornerMobile";
import {useHotkeys} from "react-hotkeys-hook";
import {getNodeWithNewIds} from "../../service/NodeService";
import {RootState} from "../../store";
import {PageNode} from "../../types/PageNode";
import { openModal } from "../../reducers/modalSlice";

const MobileEditBar = ({}) => {
    const queryString = window.location.search;
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const activeNode = useSelector((state: RootState) => state.nodeModal.value);
    const activeContainerNode = useSelector((state: RootState) => state.activeContainerNode.activeContainerNode);
    const dispatch = useDispatch()
    const [nodeState, setNodeState] = useState<PageNode>({...activeNode});
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode)
    const isMobileMovement = new URLSearchParams(queryString).get('isMovement') === "true" ? true : false

    useEffect(() => {
        setNodeState({...activeNode});
    }, [activeNode]);

    let elements: any[] = [];
    let parentExists = true;

    parentExistsFunc(activeNode, nodes, parentExists, elements)

    let getActiveTag = document.getElementById("blockContent" + activeNode.id)
    let blockIndentHeight: React.CSSProperties = {}

    if (getActiveTag) {
        let blockHeight = getActiveTag.clientHeight - 10
        if (blockHeight < 30) {
            blockHeight = getActiveTag.clientHeight + 5
        }

        const viewportWidth = window.innerWidth
        const horizontalCenter = viewportWidth / 2

        const blockWidth = getActiveTag.clientWidth

        const left = getActiveTag.offsetLeft + 10 + (blockWidth / 2) - horizontalCenter

        blockIndentHeight = {
            display: "flex",
            position: 'absolute',
            top: getActiveTag.offsetTop + blockHeight + 20 + "px",
            left: "40%",
            transform: `translateX(${left}px)`,
            height: '31px',
        }
    }

    editBarHotkeys(useHotkeys, activeNode, dispatch, nodes)

        useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl: any) {
            const tooltip = new Tooltip(tooltipTriggerEl, {
                trigger: 'hover'
            });

            tooltipTriggerEl.addEventListener('click', () => {
                setTimeout(() => {
                    tooltip.hide();
                }, 100);
            });

            return tooltip; 
        });

        return () => {
            document.querySelectorAll(".tooltip-custom").forEach(item => item.classList.remove("show"))
        }
    }, []);

    return (
        <>
            <div className='fixed-top z-index-value'>
                <div
                    className="position-absolute bg-white d-flex absolute-mobile-edit-block justify-content-around align-items-center w-100">
                    <div className=''>
                        <button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    Modal.getOrCreateInstance('#edit-block-modal').show()
                                    dispatch(openModal({modalName: "edit-block-modal"}))
                                }}>
                            <i className="bi bi-pencil"></i>
                        </button>
                    </div>
                    <div className=''>
                        <button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                onClick={() => {
                                    moveBackAction(dispatch, activeNode, nodes, isMobileMovement)
                                }}><i className="bi bi-arrow-bar-up"></i>
                        </button>
                    </div>
                    <div className=''>
                        <button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                onClick={() => {
                                    moveForwardAction(dispatch, activeNode, nodes, isMobileMovement)
                                }}><i className="bi bi-arrow-bar-down"></i>
                        </button>
                    </div>
                    <div className=''>
                        <button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlerCopy(activeNode);
                                }}><i className="bi bi-stickies"></i></button>
                    </div>
                    <div className=''>
                        {activeNode ?
                            (<button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                     onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(deleteNode({removeNode: activeNode}))
                                        dispatch(changeActiveModalNode(undefined));
                                        handlerCopy(activeNode)
                                     }}><i className="bi bi-scissors"></i></button>) :
                            (<button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"><i
                                className="bi bi-scissors"></i>
                            </button>)
                        }
                    </div>
                    <div className=''>
                        {activeNode ?
                            (<button type="button" className="mx-2 btn px-1 py-0 btn-outline-primary border-0"
                                     onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(deleteNode({removeNode: activeNode}))
                                        dispatch(changeActiveModalNode(undefined));
                                     }}><i className="bi bi-trash"></i>
                                </button>
                            )
                            :
                            (<button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0">
                                <i className="bi bi-trash"></i>
                            </button>)
                        }
                    </div>
                    <div className="dropdown d-flex align-items-center">
                        <span className="mx-2 bg-secondary-light px-1 rounded" role="button" data-bs-toggle="dropdown"
                              aria-expanded="false">
                            <i className="bi bi-three-dots text-primary"></i>
                        </span>

                        <ul className="dropdown-menu bg-primary">
                            <li><span className="dropdown-item bg-primary">
                                <button type="button" className="text-start w-100 btn btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            pasteCopyNode(dispatch, activeNode);
                                        }}><i className="bi bi-clipboard-check me-2"></i>Paste block
                        </button>
                            </span></li>
                            <li>
                                <span className="dropdown-item bg-primary">
                                <button type="button" className="text-start w-100 btn btn-sm btn-primary"
                                        onClick={() => {
                                            let copyOfActive = {...activeNode}
                                            copyOfActive = getNodeWithNewIds(copyOfActive)
                                            dispatch(addNode({
                                                activeNode: {id: activeNode.parentId},
                                                newNode: copyOfActive
                                            }))
                                        }}><i className="bi bi-clipboard-check me-2"></i>Duplicate block
                        </button>
                            </span></li>
                            {nodeState.tagName !== "img" && isAdvancedMode &&
                                <li>
                                    <span className="dropdown-item bg-primary">
                                        <button type="button" className="text-start w-100 btn btn-sm btn-primary"
                                                onClick={() => changeHiddenMobile(nodeState, setNodeState, dispatch)}>
                                            {nodeState.hidden === 'mobileHidden' ?
                                                <>
                                                    <i className="bi bi-eye me-2"></i>
                                                    Show Content
                                                </> :
                                                <>
                                                    <i className="bi bi-eye-slash me-2"></i>
                                                    Hide Content
                                                </>
                                            }
                                        </button>
                                    </span>
                                </li>
                            }

                            <li><span className="dropdown-item bg-primary"><ExportNode activeNode={activeNode}/></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style={blockIndentHeight} onClick={(e) => {
                e.stopPropagation();
            }}>
                {activeNode.className !== "container-fluid" && <div className="d-flex align-items-center border-primary border me-2 rounded bg-white"  data-bs-toggle="tooltip"
                         data-bs-placement="bottom" data-bs-title="Add Column"
                         data-bs-custom-class="tooltip-custom">
                        <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                onClick={() => parentPlusButtonClick(dispatch, activeContainerNode, Modal, "#edit-block-modal")}>
                            <i className="bi bi-columns-gap"></i>
                        </button>
                    </div>}
                <DragCornerMobile getActiveTag={getActiveTag} activeNode={activeNode}
                                  onChange={(height, changedNode) => setNodeState(changedNode)}/>
            </div>
        </>
    );
};

export default MobileEditBar;