import { NodeType, PageNode } from "../../../types/PageNode";
import { getNewNode, getNodeWithNewIds } from "../../NodeService";
import { CustomStyleField } from "../../../types/CustomStyleField";

export default class DivAccordionNodeFactory {
  public static getAccordionNode = (parentNode: PageNode) => {
    const newNode: PageNode = getNewNode(NodeType.DivNode, parentNode, {
      tagName: "div",
      className: "accordion",
      type: NodeType.Accordion,
      expanded: true,
      accordionIconOpen: "chevron-down",
      accordionIconClose: "chevron-up",
    });
    // create accordion header
    const accordionHeader = getNewNode(NodeType.DivNode, newNode, {
      tagName: "div",
      className: "accordion-header",
      type: NodeType.AccordionHeaderNode,
      isHidden: true,
    });

    const containerHeader = getNewNode(NodeType.DivNode, accordionHeader, {
      tagName: "div",
      className: "container-fluid",
      backClassesIndexed: { "gx-0": "gx-0" },
      breadCrumbTitle: "container-fluid",
      customStyle: {
        [CustomStyleField.Padding]: "16px",
        [CustomStyleField.BorderBottom]: "1px solid",
      },
      isMixedBorder: true,
    });

    const textHeader: PageNode = getNewNode(
      NodeType.TextNode,
      containerHeader,
      {
        tagName: "text",
        className: "",
        value: "<div><strong>Accordion Title</strong></div>",
        disallowedAddElements: true,
      }
    );
    containerHeader.nodes.push(textHeader);

    accordionHeader.nodes.push(containerHeader);
    newNode.nodes.push(accordionHeader);

    // create accordion body
    const accordionBody = getNewNode(NodeType.DivNode, newNode, {
      tagName: "div",
      className: "accordion-body",
      type: NodeType.AccordionBodyNode,
      isHidden: true,
    });
    const container = getNewNode(NodeType.DivNode, accordionBody, {
      tagName: "div",
      className: "container-fluid",
      backClassesIndexed: { "gx-0": "gx-0" },
      breadCrumbTitle: "container-fluid",
      customStyle: {
        [CustomStyleField.Padding]: "16px",
      },
    });
    const textNode: PageNode = getNewNode(NodeType.TextNode, container, {
      tagName: "text",
      className: "",
      value:
        "<div><strong>This is the first item's accordion body.</strong> It is shown by default.</div>",
      disallowedAddElements: true,
    });
    container.nodes.push(textNode);

    accordionBody.nodes.push(container);
    newNode.nodes.push(accordionBody);

    return getNodeWithNewIds(newNode);
  };
}
