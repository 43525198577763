import EditBar from "./EditBar";
import React, {useState, useLayoutEffect} from "react";
import { useSelector } from "react-redux";
import MobileEditBar from "./MobileEditBar";
import EditBarSmallElement from "./EditBarSmallElement";

const EditBarView = () => {
    const activeNode = useSelector((state) => state.nodeModal.value);
    const isMobileViewDimension = useSelector(
        (state) => state.appStorage.isMobileViewDimension
    );
    const previewState = useSelector((state) => state.appStorage.preview);
    const readOnlyMode = useSelector((state) => state.appStorage.isReadOnlyMode);

    const [getTagWidth, setTagWidth] = useState(0);
    const [getTagHeight, setTagHeight] = useState(0);

    useLayoutEffect(() => {
        if (activeNode) {
                const getTag = document.getElementById("blockContent" + activeNode.id);
                if (getTag) {
                    const rect = getTag.getBoundingClientRect();
                    setTagWidth(rect.width);
                    setTagHeight(rect.height);
                }
        }
    }, [activeNode]);

    const shouldRenderView = () =>
        activeNode.parentId !== null && !readOnlyMode && !previewState;


    const View = () => {
        if (isMobileViewDimension) {
            return (
                <MobileEditBar/>
            );
        } else if (!isMobileViewDimension && getTagWidth < 60 && getTagHeight < 60) {
            return <EditBarSmallElement activeNode={activeNode} />;
        } else {
            return <EditBar activeNode={activeNode} />;
        }
    };
    return (
        <>
            {activeNode && shouldRenderView() && (
                <>
                    <View />
                </>
            )}
        </>
    );
};

export default EditBarView;