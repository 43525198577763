import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Modal, Tooltip} from "bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {deleteNode} from "../../reducers/nodes";
import {
    changeHidden,
    editBarHotkeys,
    findNodeWhichAreContainer,
    handlerCopy,
    moveBackAction,
    moveForwardAction,
    parentExistsFunc,
    parentPlusButtonClick,
    pasteCopyNode,
    pasteCopyNodeAbove,
    pasteCopyNodeBelow
} from "../../service/EditBarService"
import {changeActiveModalNode} from "../../reducers/node-modal";
import {useHotkeys} from "react-hotkeys-hook";
import DragCorner from "../context-menu/block/modules/DragCorner";
import { openModal } from "../../reducers/modalSlice";

const EditBarSmallElement = ({activeNode}) => {
    const nodes = useSelector((state) => state.nodes.present.value);
    const [nodeState, setNodeState] = useState({...activeNode})
    const dispatch = useDispatch()
    const activeContainerNode = useSelector((state) => state.activeContainerNode.activeContainerNode);
    let elements = [];
    let parentExists = true;
    const [blockIndentHeight, setBlockIndentHeight] = useState(null)
    const windowWidth = useRef(window.innerWidth);


    parentExistsFunc(activeNode, nodes, parentExists, elements)

    let getActiveTag = document.getElementById("blockContent" + activeNode.id)

    useLayoutEffect(() => {
        if (getActiveTag) {
            let blockHeight = getActiveTag.clientHeight - 20
            if (blockHeight < 30) {
                blockHeight = getActiveTag.clientHeight + 5
            }
            const rect = getActiveTag.getBoundingClientRect();
            const offsetLeft = rect.left + window.scrollX;
            const activeTagOffsetCetner = getActiveTag.offsetWidth / 2;
            let blockLeft = offsetLeft + activeTagOffsetCetner;
            setBlockIndentHeight({
                top: window.scrollY + rect.y + rect.height + 15 + "px",
                left: blockLeft  + "px",
                height: '37px',
                transform: 'translateX(-50%)',
            });
        }
    }, [activeNode, getActiveTag])

    useLayoutEffect(() => {
        const editBlock = document.querySelector('.absolute-edit-block');
        if (editBlock) {
            const editBlockRect = editBlock.getBoundingClientRect();
            const parentPlusButton = editBlock.closest('.parent-plus-button');
            if (editBlockRect.right >= windowWidth.current && !parentPlusButton) {
                setBlockIndentHeight({...blockIndentHeight, right: "10px", left: undefined, transform: undefined});
            } else if (editBlockRect.left < 0 && !parentPlusButton) {
                setBlockIndentHeight({...blockIndentHeight, left: "10px", transform: undefined});
            }
        }
    }, [blockIndentHeight]);

    useEffect(() => {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    editBarHotkeys(useHotkeys, activeNode, dispatch, nodes)

    return (
        <>
            {getActiveTag &&
                <div className="position-absolute d-flex absolute-edit-block" style={blockIndentHeight}
                     onClick={(e) => {
                         e.stopPropagation();
                     }}>
                    <div className="border rounded border-primary d-flex bg-white">
                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Add Block"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary-plus border-0"
                                    onClick={() => {
                                        if (activeNode.disallowedAddElements) {
                                            let containerNode = findNodeWhichAreContainer(activeNode, nodes[0]);
                                            dispatch(changeActiveModalNode(containerNode));
                                        }
                                        Modal.getOrCreateInstance('#container-to-add-modal').show();
                                    }}>
                                <i className="bi bi-plus-lg "></i>
                            </button>
                        </div>
                        <div className="vl bg-primary"></div>
                        <div className="d-flex align-items-center" data-bs-toggle="tooltip"
                             data-bs-placement="bottom" data-bs-title="Edit"
                             data-bs-custom-class="tooltip-custom">
                            <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        Modal.getOrCreateInstance('#edit-block-modal').show()
                                        dispatch(openModal({modalName: "edit-block-modal"}))
                                    }}>
                                <i className="bi bi-pencil"></i>
                            </button>
                        </div>
                        <div className="vl bg-primary"></div>
                        <div className="dropdown d-flex align-items-center">
                        <span className="mx-2 px-1 rounded" role="button" data-bs-toggle="dropdown"
                              aria-expanded="false">
                            <i className="bi bi-three-dots text-primary"></i>
                        </span>
                            <ul className="dropdown-menu bg-primary">
                                {/*<li><span className="dropdown-item bg-primary">*/}
                                {/*    <button type="button" className="btn btn-sm btn-primary" onClick={(e) => {*/}
                                {/*        e.stopPropagation();*/}
                                {/*        Modal.getOrCreateInstance('#edit-block-modal').show()*/}
                                {/*    }}> <i className="bi bi-pencil"></i> Edit</button>*/}
                                {/*</span></li>*/}
                                <li><span className="dropdown-item bg-primary">
                                <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlerCopy(activeNode);
                                        }}><i className="bi bi-stickies me-2"></i> Copy</button>
                            </span></li>
                                <li><span className="dropdown-item bg-primary">
                                {activeNode ?
                                    (<button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                             onClick={() => {
                                                dispatch(deleteNode({removeNode: activeNode}))
                                                dispatch(changeActiveModalNode(undefined));
                                                handlerCopy(activeNode)
                                             }}><i className="bi bi-scissors me-2"></i> Cut</button>) :
                                    (<button type="button" className="w-100 text-start btn btn-sm btn-primary"><i
                                        className="bi bi-scissors me-2"></i> Cut
                                    </button>)
                                }
                            </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNode(dispatch, activeNode);
                                         }}><i className="bi bi-box-arrow-in-down me-2"></i> Paste
                             </button>
                            </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                        <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                                onClick={() => {
                                                    moveBackAction(dispatch, activeNode, nodes)
                                                }}><i className="bi bi-layer-forward me-2"></i> Block Up
                                        </button>
                             </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                        <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                                onClick={() => {
                                                    moveForwardAction(dispatch, activeNode, nodes)
                                                }}><i className="bi bi-layer-backward me-2"></i> Block Down
                                        </button>
                                     </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNodeAbove(dispatch, activeNode);
                                         }}><i className="bi bi-arrow-bar-up me-2"></i> Paste Above Block
                             </button>
                            </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNode(dispatch, activeNode);
                                         }}><i className="bi bi-clipboard-check me-2"></i> Paste in block
                             </button>
                            </span></li>
                            <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                         onClick={(e) => {
                                             e.stopPropagation();
                                             pasteCopyNodeBelow(dispatch, activeNode, nodes);
                                         }}><i className="bi bi-arrow-bar-down me-2"></i> Paste Below Block
                             </button>
                            </span></li>
                            {nodeState.tagName !== "img" &&
                                <li><span id="css-link-edit" className="dropdown-item bg-primary">
                                 <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                         onClick={() => changeHidden(nodeState, setNodeState, dispatch)}>
                                     {nodeState.hidden ?
                                         <>
                                             <i className="bi bi-eye me-2"></i> Show Block
                                         </>
                                         :
                                         <>
                                             <i className="bi bi-eye-slash me-2"></i> Hide Block
                                         </>
                                     }
                                </button>
                            </span></li>
                            }
                                <li><span className="dropdown-item bg-primary">
                                <button type="button" className="w-100 text-start btn btn-sm btn-primary"
                                        onClick={() => {
                                            dispatch(deleteNode({removeNode: activeNode}))
                                            dispatch(changeActiveModalNode(undefined));
                                        }}> <i className="bi bi-trash me-2"></i> Delete</button>
                            </span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex align-items-center border-primary border ms-2 rounded bg-white" data-bs-toggle="tooltip"
                         data-bs-placement="bottom" data-bs-title="Add Column"
                         data-bs-custom-class="tooltip-custom">
                        <button type="button" className="mx-2 btn btn-sm px-1 py-0 btn-outline-primary border-0"
                                onClick={() => parentPlusButtonClick(dispatch, activeContainerNode, Modal, "#edit-block-modal")}>
                            <i className="bi bi-columns-gap"></i>
                        </button>
                    </div>
                    <DragCorner getActiveTag={getActiveTag} activeNode={activeNode}
                                onChange={(height, changedNode) => setNodeState(changedNode)}/>
                </div>
            }
        </>
    );
};

export default EditBarSmallElement