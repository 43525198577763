import React, {useState} from 'react'
import {
    ChangeNodeFunction,
    changeStyleByValue,
    deleteCustomStyleByName,
} from "../../../service/editModalService";
import {CustomStyleField} from "../../../types/CustomStyleField";
import SelectColor from "./SelectColor";
import {ImageNode} from "../../../types/PageNode";

interface ColorPicketModuleProps {
    editableNode: ImageNode,
    changeNodeFunction: ChangeNodeFunction,
    isMobileStyle: boolean
}

const ColorPickerModule: React.FC<ColorPicketModuleProps> = ({
                                                                 editableNode,
                                                                 changeNodeFunction,
                                                                 isMobileStyle
}) => {
    const [showBlock, setShowBLock] = useState<string>("");
    const changeBackgroundStyleFunction = (styleName: string, color: string, isMobileStyle: boolean): void => {
        const tmpNode: ImageNode = changeStyleByValue(styleName, color, editableNode, isMobileStyle)

        if (isMobileStyle) {
            tmpNode.customStyleMobile = Object.assign({...tmpNode.customStyleMobile}, {[CustomStyleField.Background]: color});
            tmpNode.customStyleMobile = Object.assign(
                {...tmpNode.customStyleMobile},
                {[CustomStyleField.BackgroundImage]: ""}
            );
            delete tmpNode["customStyleMobile"][CustomStyleField.BackgroundImage]
        } else {
            tmpNode.customStyle = Object.assign({...tmpNode.customStyle}, {[CustomStyleField.Background]: color});
            tmpNode.customStyle = Object.assign(
                {...tmpNode.customStyle},
                {[CustomStyleField.BackgroundImage]: ""}
            );
            delete tmpNode["customStyle"][CustomStyleField.BackgroundImage]
        }
        changeNodeFunction(tmpNode)
    }

    const deleteBackgroundStyleFunction = (styleName: string, isMobileStyle: boolean): void => {
        const tmpNode: ImageNode = deleteCustomStyleByName(styleName, editableNode, isMobileStyle)

        if (isMobileStyle) {
            tmpNode.customStyleMobile = Object.assign(
                {...tmpNode.customStyleMobile},
                {[CustomStyleField.Background]: ""}
            );
            delete tmpNode["customStyleMobile"][CustomStyleField.Background]
        } else {
            tmpNode.customStyle = Object.assign(
                {...tmpNode.customStyle},
                {[CustomStyleField.Background]: ""}
            );
            delete tmpNode["customStyle"][CustomStyleField.Background]
        }
        changeNodeFunction(tmpNode)
    }

    return (
        <div className="mb-3">
            <SelectColor editableNode={editableNode}
                         styleName={CustomStyleField.BackgroundColor} labelName={'Background Color'}
                         showBlock={showBlock}
                         setShowBLock={setShowBLock} changeStyleFunction={changeBackgroundStyleFunction}
                         deleteStyleByName={deleteBackgroundStyleFunction}
                         isMobileStyle={isMobileStyle}/>
        </div>
    )
}

export default ColorPickerModule