import React, {useEffect, useState} from "react";
import {PageNode} from "../../../../types/PageNode";
import {ChangeNodeFunction} from "../../../../service/editModalService";
import {OnClickActionFile, OnClickActionType} from "../../../../types/OnClickAction";
import FileUploadComponent from "../../../FileUpload/FileUploadComponent";
import {Modal} from "bootstrap";
import UploadedFilePreview from "../../../Common/UploadedFilePreview";
import TargetPicker from "../../../EditModal/components/TargetPicker";
import {CustomStyleField} from "../../../../types/CustomStyleField";
import Vcard from "./Vcard";
import { FileManagerFile } from "../../../../service/FileManager/FileManagerFile";
import { useParams } from "react-router-dom";
import { useGetPageQuery, usePageCategoryQuery } from "../../../../service/Page/PageService.hooks";

interface IncomeProperties {
    editableNode: PageNode,
    changeFunction: ChangeNodeFunction
}

export interface IVcardFields {
    firstName: string,
    lastName: string,
}

const ActionSettingsComponent: React.FC<IncomeProperties> = ({editableNode, changeFunction}: IncomeProperties) => {
    const {id} = useParams()
    const {data: pageCategories, loading: categoriesLoading} = usePageCategoryQuery()
    const {data: page, loading: pageLoading} = useGetPageQuery({variables: {id: +id}})
    const pagePDF = (pageCategories && page) && pageCategories.pageCategory[0].pages.find((pagePdf) => pagePdf.id === page.page.parentTemplate?.id)
    const [showFileManager, setShowFileManager] = useState(false)
    let onClickAction: OnClickActionFile = editableNode.onClickAction as OnClickActionFile ? {...editableNode.onClickAction as OnClickActionFile} :
        {
            fileObject: undefined,
            type: pagePDF ? OnClickActionType.OpenFile : undefined,
            value: undefined,
        }

    const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const targetValue = parseInt(e.target.value)
        if (targetValue !== OnClickActionType.OpenFile) {
            delete onClickAction.fileObject;
        }
        if (targetValue !== OnClickActionType.SaveVcard) {
            delete onClickAction.base64Vcard;
            editableNode.onClickAction = onClickAction;
        }
        if (targetValue === OnClickActionType.OpenLink) {
            onClickAction.type = OnClickActionType.OpenLink
            editableNode.onClickAction = onClickAction
        } else if (targetValue === OnClickActionType.WriteEmail) {
            onClickAction.type = OnClickActionType.WriteEmail
            editableNode.onClickAction = onClickAction
        } else if (targetValue === OnClickActionType.CallPhone) {
            onClickAction.type = OnClickActionType.CallPhone
            editableNode.onClickAction = onClickAction
        } else if (targetValue === OnClickActionType.OpenFile) {
            onClickAction.type = OnClickActionType.OpenFile
            editableNode.onClickAction = onClickAction
        } else if (targetValue === OnClickActionType.SaveVcard) {
            onClickAction.type = OnClickActionType.SaveVcard
            editableNode.onClickAction = onClickAction
        } else if (targetValue === OnClickActionType.None) {
            onClickAction.type = OnClickActionType.None;
            onClickAction.value = undefined;
            editableNode.onClickAction = onClickAction
        }else {
            delete editableNode.onClickAction;
        }

        changeFunction(editableNode);
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onClickAction.value = e.target.value;
        editableNode.onClickAction = onClickAction
        changeFunction(editableNode);
    }

    const onFileChoose = (file: FileManagerFile) => {
        onClickAction.value = file.serverPath;
        onClickAction.fileObject = file;
        editableNode.onClickAction = onClickAction
        changeFunction(editableNode);
    }

    const handleVcardChange = (base64File: string) => {
        onClickAction.value = null;
        onClickAction.base64Vcard = base64File;
        editableNode.onClickAction = onClickAction
        changeFunction(editableNode);
    }

    const getPreview = (file: FileManagerFile) => {
        if (file.extension === 'jpg' || file.extension === 'png') {
            return file.serverPath;
        } else {
            return '/logo192.png'
        }
    }

    return (
        <div>
            <h5 className="mt-4 mb-3">Action with element</h5>
            <div className={`col-md-12 form-floating mb-3`}>
                <select className="form-select " id="actionClick" name="actionClick"
                        value={onClickAction.type ?? ''} onChange={handleChangeType}>
                    <option value={OnClickActionType.None}>None</option>
                    <option value={OnClickActionType.OpenLink}>Open Url</option>
                    <option value={OnClickActionType.WriteEmail}>Write an email</option>
                    <option value={OnClickActionType.CallPhone}>Call to phone number </option>
                    <option value={OnClickActionType.OpenFile}>Open File</option>
                    <option value={OnClickActionType.SaveVcard}>Save Vcard</option>

                    {/*<option value='cart'>Cart</option>*/}
                </select>
                <label htmlFor="actionClick" className="form-label">On click</label>
            </div>
            {onClickAction.type === OnClickActionType.OpenLink &&
                <>
                <div className="form-floating mb-3">
                    <input
                        id={'edit-block-modal-href-input'}
                        onChange={handleInputChange}
                        type='text'
                        placeholder={'Set destination link'}
                        value={onClickAction.value}
                        className={'form-control'}
                    />
                    <label htmlFor="edit-block-modal-href-input" className="form-label">Url to open</label>
                </div>
                    {/*<TargetPicker onChange={(e) => {*/}
                    {/*    editableNode[CustomStyleField.Target] = e.target.value*/}
                    {/*    changeNodeFunction(editableNode)*/}
                    {/*}} value={editableNode[CustomStyleField.Target]}/>*/}
                </>
            }
            {onClickAction.type === OnClickActionType.WriteEmail &&
                <div className="form-floating mb-3">
                    <input
                        id={'edit-block-modal-href-input'}
                        onChange={handleInputChange}
                        type='text'
                        placeholder={'Set email'}
                        value={onClickAction.value}
                        className={'form-control'}
                    />
                    <label htmlFor="edit-block-modal-href-input" className="form-label">Email address</label>
                </div>}
            {onClickAction.type === OnClickActionType.CallPhone &&
                <div className="form-floating mb-3">
                    <input
                        id={'edit-block-modal-href-input'}
                        onChange={handleInputChange}
                        type='text'
                        placeholder={'Set phone number'}
                        value={onClickAction.value}
                        className={'form-control'}
                    />
                    <label htmlFor="edit-block-modal-href-input" className="form-label">Phone number</label>
                </div>
            }
            {onClickAction.type === OnClickActionType.OpenFile &&
                <div className="form-floating mb-3">
                    {!editableNode.onClickAction?.value || !onClickAction.fileObject ? (
                        <div className="btn btn-outline-primary bg-btn-img-color text-primary p-3" onClick={() => {
                            setShowFileManager(true)
                            Modal.getOrCreateInstance('#edit-block-modal').hide()
                        }}>
                            <i className="bi bi-download"></i> Choose file to open
                        </div>) : <>
                        <div className='row'>
                            <div className={'col-12'}>
                                <h5>On click this file would be displayed</h5>
                            </div>
                        </div>

                        {editableNode.onClickAction.value &&
                            <UploadedFilePreview filePath={editableNode.onClickAction.value}
                                                 size={((editableNode.onClickAction as OnClickActionFile).fileObject?.size/1024/1024).toFixed(2).toString()}
                                                 onRemoveFile={() => {
                                                     onClickAction.value = undefined
                                                     onClickAction.fileObject = undefined
                                                     editableNode.onClickAction = onClickAction
                                                     changeFunction(editableNode)
                                                 }}
                                                 onClick={() => {
                                                     setShowFileManager(true)
                                                     Modal.getOrCreateInstance('#edit-block-modal').hide()
                                                 }}
                                                 fileName={onClickAction.fileObject.title}
                                                 />
                        }
                    </>
                    }
                </div>
            }
            {onClickAction.type === OnClickActionType.SaveVcard && <Vcard handleVcardChange={handleVcardChange} value={onClickAction.value} />}

            {/*<hr/>*/}
            {showFileManager && <FileUploadComponent onClose={() => {
                Modal.getOrCreateInstance('#edit-block-modal').show()
                setShowFileManager(false)
            }} onFileChoose={onFileChoose} allowedFileTypes={['*']}/>}
        </div>

    );
};
export default ActionSettingsComponent