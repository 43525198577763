import {
    addNode,
    changeNodeHidden,
    deleteNode,
    findNode,
    moveBack,
    moveForward,
    updateRootNode
} from "../reducers/nodes";
import {changeActiveModalNode} from "../reducers/node-modal";
import {addNewIdForChildNodes, changeDataBSTargetId} from "./NodeService";
import {DivRowNode, PageNode} from "../types/PageNode";
import { useDispatch, useSelector } from "react-redux";
import { ActiveTab, setActiveTab } from "../reducers/active-tab";
import { RootState } from "../store";

const parentExistsFunc = (searchNode: PageNode, nodes: PageNode[], parentExists: boolean, elements: any) => {
    while (parentExists) {
        if (searchNode && searchNode.parentId != '1') {
            let parentNode = findNode(nodes[0], searchNode.parentId)
            if (parentNode === null) {
                parentExists = false
            } else {
                elements.unshift(parentNode);
                searchNode = parentNode;
            }
        } else {
            parentExists = false;
        }
    }
    return elements
}

const moveForwardAction = (dispatch: any, activeNode: PageNode, nodes: any, isMobileMovement: boolean) => {
    let nodeToAction = findElementWhoseParentWhichHaveMoreThanOneNode(activeNode, nodes[0]);
    if (nodeToAction !== null) {
        dispatch(moveForward({node: nodeToAction, isMobile: isMobileMovement}));
    }
}

const moveBackAction = (dispatch: any, activeNode: PageNode, nodes: any, isMobileMovement: boolean) => {
    let nodeToAction = findElementWhoseParentWhichHaveMoreThanOneNode(activeNode, nodes[0]);
    if (nodeToAction !== null) {
        dispatch(moveBack({node: nodeToAction, isMobile: isMobileMovement}));
    }
}

export const findElementWhoseParentWhichHaveMoreThanOneNode = (node: PageNode, nodes: PageNode[]): PageNode | null => {
    if (node.id !== '1') {
        let parentNode = findNode(nodes, node.parentId);

        if (parentNode.nodes.length > 1 || parentNode.id=='1') {
            return node;
        } else {
            return findElementWhoseParentWhichHaveMoreThanOneNode(parentNode, nodes);
        }
    } else {
        return null;
    }
}
const findNodeWhichAreContainer = (node: PageNode, nodes: PageNode[], className: string = null): DivRowNode | null => {
    if (node.parentId != '1') {
        if ((className === null && node.tagName === 'div') || (className !== null && node.className === className)) {
            return node as DivRowNode;
        } else {
            let parentNode = findNode(nodes, node.parentId);
            return findNodeWhichAreContainer(parentNode, nodes,className);
        }
    } else {
        return null;
    }
}


const handlerCopy = (activeNode: PageNode) => {
    navigator.clipboard.writeText(JSON.stringify(activeNode)).then()
}
export const duplicateNode = (activeNode: PageNode, nodes: PageNode[]) => {
    //TODO: Add duplicate
    let parentNode: PageNode = findNode(nodes, activeNode.parentId)
    parentNode.nodes.push()
}


const pasteCopyNode = (dispatch: any, activeNode: PageNode) => {
    const div = document.querySelector(`#blockContent${activeNode.id}`).outerHTML
    if(div.endsWith(`/${activeNode.tagName}>`)) {
        navigator.clipboard.readText()
            .then(text => {
                let newNode = JSON.parse(text);
                newNode.id = Date.now();
                const idArray = addNewIdForChildNodes(newNode);
                changeDataBSTargetId(newNode, idArray)
                dispatch(addNode({activeNode, newNode}));
                dispatch(changeActiveModalNode(newNode));
            })
    }
}

const updateNodesRecursively = (oldNode: PageNode, newNode: PageNode, parentNode: PageNode): PageNode => {
    if (oldNode.id === parentNode.id) {
        return newNode;
    }

    return {
        ...oldNode,
        nodes: oldNode.nodes.map((node) =>
            updateNodesRecursively(node, newNode, parentNode)
        ),
    };
}
const pasteCopyNodeAbove = (dispatch: any, activeNode: PageNode, nodes: PageNode[]) => {
    navigator.clipboard.readText().then((text) => {
        let newNode = JSON.parse(text);
        newNode.id = Date.now();
        const idArray = addNewIdForChildNodes(newNode);
        changeDataBSTargetId(newNode, idArray)
        newNode.parentId = activeNode.parentId;

        const parentNode = findNode(nodes[0], activeNode.parentId);
        const activeNodeIndex = parentNode.nodes.findIndex(
            (node: PageNode) => node.id === activeNode.id
        );

        const updatedParentNode = {
            ...parentNode,
            nodes: parentNode.nodes.slice()
        };
        updatedParentNode.nodes.splice(activeNodeIndex, 0, newNode);

        const updatedRootNode = updateNodesRecursively(nodes[0], updatedParentNode, parentNode);

        dispatch(updateRootNode({ newRootNode: updatedRootNode }));
        dispatch(changeActiveModalNode(newNode));
    });
};

const pasteCopyNodeBelow = (dispatch: any, activeNode: PageNode, nodes: PageNode[]) => {
    navigator.clipboard.readText().then((text) => {
        let newNode = JSON.parse(text);
        newNode.id = Date.now();
        const idArray = addNewIdForChildNodes(newNode);
        changeDataBSTargetId(newNode, idArray)
        newNode.parentId = activeNode.parentId;

        const parentNode = findNode(nodes[0], activeNode.parentId);
        const activeNodeIndex = parentNode.nodes.findIndex(
            (node: PageNode) => node.id === activeNode.id
        );

        const updatedParentNode = {
            ...parentNode,
            nodes: parentNode.nodes.slice()
        };
        updatedParentNode.nodes.splice(activeNodeIndex + 1, 0, newNode);

        const updatedRootNode = updateNodesRecursively(nodes[0], updatedParentNode, parentNode);

        dispatch(updateRootNode({ newRootNode: updatedRootNode }));
        dispatch(changeActiveModalNode(newNode));
    });
}

const activeParentNode = (activeNode: PageNode, nodes: PageNode[], dispatch: any) => {
    let parentNode = findNode(nodes[0], activeNode.parentId);
    return new Promise((resolve: any) => {
        dispatch(changeActiveModalNode(parentNode));
        resolve();
    });
};


const editBarHotkeys = (func: any, activeNode: PageNode, dispatch: any, nodes: PageNode[]) => {
    func("ctrl+c, cmd+c", () => {handlerCopy(activeNode)})
    func("ctrl+v, cmd+v", () => {
        if (!activeNode.disallowedAddElements) {
            activeParentNode(activeNode, nodes, dispatch)
                .then(() => pasteCopyNode(dispatch, activeNode))
        } else {
            pasteCopyNode(dispatch, activeNode)
        }
    });
    func("ctrl+x, cmd+x", () => {
        dispatch(deleteNode({removeNode: activeNode}))
        dispatch(changeActiveModalNode(undefined));
        handlerCopy(activeNode)
    })
    func("del, backspace", (() => {
        dispatch(changeActiveModalNode(null))
        dispatch(deleteNode({removeNode: activeNode}))
        }))
}


const changeHidden = (nodeState:PageNode, setNodeState:any, dispatch:any) => {
    let tmpNode = { ...nodeState };
    tmpNode.hidden = tmpNode.hidden === 'desktopHidden' ? '': 'desktopHidden';
    setNodeState(tmpNode);
    dispatch(changeNodeHidden({ id: tmpNode.id, type: 'hidden', hidden: tmpNode.hidden }));
};

const changeHiddenMobile = (nodeState:PageNode, setNodeState:any, dispatch:any) => {
    let tmpNode = { ...nodeState };
    tmpNode.hidden = tmpNode.hidden === 'mobileHidden' ? '': 'mobileHidden';
    setNodeState(tmpNode);
    dispatch(changeNodeHidden({ id: tmpNode.id, type: 'hidden', hidden: tmpNode.hidden }));
};

const parentPlusButtonClick = (
    dispatch:any,
    activeContainerNode:PageNode,
    Modal:any,
    modalSelector:any
) => {
    dispatch(changeActiveModalNode(activeContainerNode));
    Modal.getOrCreateInstance(modalSelector).show();
    dispatch(setActiveTab(ActiveTab.CONTENT))
};

export {
    moveForwardAction,
    moveBackAction,
    handlerCopy,
    pasteCopyNode,
    parentExistsFunc,
    editBarHotkeys,
    findNodeWhichAreContainer,
    pasteCopyNodeAbove,
    pasteCopyNodeBelow,
    activeParentNode,
    changeHidden,
    changeHiddenMobile,
    parentPlusButtonClick
}