import React, { ComponentType } from "react";
import {ImageNode} from "../../../types/PageNode";
import { customIconsSVG } from "./custom-icons";
import { customIcons, iconOptions } from "../../../data/iconOptions";

interface IconItemProps {
    icon: string,
    selectedIcon: string,
    editableNode: ImageNode,
    handleSelectedIconChange: (editedNode: ImageNode, icon: string) => void,
    key?: string
    isCustomIcon?: boolean
}

const IconItem: React.FC<IconItemProps> = ({icon, editableNode, selectedIcon, handleSelectedIconChange, key, isCustomIcon=false}) => {

    const isActive = selectedIcon === icon && (editableNode.src === null || !editableNode.src);

    return (
        <div
            className={`btn active-icon ${isActive ? 'active' : ''}`}
            style={{
                width: '100%',
                paddingBottom: '83%',
                position: 'relative'
            }}
            onClick={() => handleSelectedIconChange(editableNode, icon)}
        >
            <div className='d-flex align-items-center justify-content-center select-icon-item'>
                {!isCustomIcon && <i className={`bi bi-${icon} icon-responsive`}></i>}
                {isCustomIcon && <div dangerouslySetInnerHTML={{ __html: customIconsSVG[icon] }} />}
            </div>
        </div>
    );
};

export default IconItem;
