import React from "react";
import { customIconsSVG } from "./custom-icons";

interface IAccordionIconButtonProps {
  isActive: boolean;
  label: string;
  iconKey: string;
  onClick: () => void;
}

const AccordionIconButton: React.FC<IAccordionIconButtonProps> = ({
  isActive,
  label,
  iconKey,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={`btn btn-${
        isActive ? "" : "outline-"
      }primary d-flex justify-content-center align-items-center gap-2 w-100`}
      onClick={onClick}
    >
      <div className="active-icon active">
        <div className="d-flex align-items-center justify-content-center select-icon-item">
          {customIconsSVG[iconKey] ? (
            <div
              style={{ width: "30px", height: "30px" }}
              className="bg-white rounded p-1 border border-border-gray d-flex justify-content-center align-items-center"
              dangerouslySetInnerHTML={{
                __html: customIconsSVG[iconKey],
              }}
            />
          ) : (
            <i
              style={{ width: "30px", height: "30px" }}
              className={`bi bi-${iconKey} icon-responsive text-dark bg-white rounded p-1 border border-border-gray d-flex justify-content-center align-items-center`}
            ></i>
          )}
        </div>
      </div>
      <div>{label}</div>
    </button>
  );
};

export default AccordionIconButton;
