import React, { useState, useEffect, useRef } from "react";
import { FileManagerFile } from "./FileManagerFile";

interface FileUploaderProps {
    files: FileManagerFile[];
    onFileDelete: (file: FileManagerFile) => void;
    onFileChoose: (file: FileManagerFile) => void;
}

const fileExtensions = ['jpg', 'png', 'svg', 'jpeg']

const FileRender: React.FC<FileUploaderProps> = ({ files, onFileDelete, onFileChoose }) => {
    const [openDropdown, setOpenDropdown] = useState<number | null>(null);
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

    const getPreview = (file: FileManagerFile) => {
        if (fileExtensions.includes(file.extension.toLocaleLowerCase())) {
            return file.serverPath;
        } else {
            return 'img/file-pdf.svg';
        }
    };

    const toggleDropdown = (index: number) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const formatFileSize = (size: number) => {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    };


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (openDropdown !== null) {
                const dropdownRef = dropdownRefs.current[openDropdown];
                if (dropdownRef && !dropdownRef.contains(event.target as Node)) {
                    setOpenDropdown(null);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openDropdown]);

    if (files.length === 0)
        return (
            <h5 className={'text-center text-dark-gray '}>No Files</h5>
        );

    return (
        <>
            {files.map((file: FileManagerFile, index: number) => {
                const truncateText = (text: string, startLength: number, endLength: number): string => {
                    if (text.length <= startLength + endLength) {
                        return text;
                    }
                    return text.slice(0, startLength) + '... ' + text.slice(-endLength);
                };

                const truncatedTitle = truncateText(file.title, 12, 4);


                return (
                    <div className='col-6 col-md-2' key={file.id}>
                        <div className='cursor-pointer'>
                            <div
                                className="rounded fileChooseImg overflow-y-hidden d-flex align-items-center"
                                onClick={() => onFileChoose && onFileChoose(file)}
                            >
                                <img className='w-100' src={getPreview(file)} alt={'tmp'}/>
                            </div>
                            <div className='d-flex gap-2 justify-content-between mt-2'>
                                <span className='text-dark-gray lh-sm text-break description-text'>{truncatedTitle}</span>
                                <div className="dropdown" ref={el => dropdownRefs.current[index] = el}>
                                    <button
                                        className="border-0 bg-white"
                                        type="button"
                                        aria-expanded="false"
                                        onClick={() => toggleDropdown(index)}
                                    >
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className={`dropdown-file-uploader dropdown-menu${openDropdown === index ? ' show' : ''}`}>
                                        <li onClick={(e) => {
                                            e.stopPropagation();
                                            onFileDelete(file);
                                            setOpenDropdown(null);
                                        }}>
                                            <div className="dropdown-item">
                                                <div className='text-background-mobile'>
                                                    <i className="bi bi-trash "></i>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span className={'text-background-mobile fs-7 lh-sm'}>{formatFileSize(file.size)}</span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default FileRender;
