import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {changeActiveModalNode} from "../../reducers/node-modal";
import ImageBlock from "./display/imageBlock";
import {changeActiveContainerNode} from "../../reducers/active-container-node";
import {findNodeWhichAreContainer} from "../../service/EditBarService";
import {Modal} from "bootstrap";
import Dropdown from "../context-menu/add-element/dropdown";
import CustomStyles from "../../service/CustomsStyles";
import TextBlock from "./display/TextBlock";
import {BackClassesField} from "../../types/BackClass";
import {ButtonNode, DivColNode, NodeType, PageNode} from "../../types/PageNode";
import {DivColNodeComponent} from "./TypeNode/DivColNode";
import ButtonBlock from "./display/ButtonBlock";
import {IFrameComponent} from "./TypeNode/IFrameComponent";
import OnClickActionWrapper from "./Wrapper/OnClickActionWrapper";
import {RootState} from "../../store";
import DynamicElement from "./TypeNode/DynamicElement";
import IconBlock from "./display/IconBlock";
import {changeNewNode} from "../../reducers/app";
import { AccordionElement } from "./TypeNode/AccordionElement";


export const selfClosingTagsArray = ['hr', 'br', 'input']

interface InputProps {
    node: PageNode
    idPrefix?: string,
    isPreviewState: boolean
    additionalClasses?:string[]
}

const Node: React.FC<InputProps> = ({node, idPrefix, isPreviewState, additionalClasses}) => {
    let className = '';
    let classes: string[] = [];

    let nodeToShow = {...node};
    const isAdvancedMode = useSelector((state: RootState) => state.appStorage.isAdvancedMode);
    const nodes = useSelector((state: RootState) => state.nodes.present.value);
    const previewState = isPreviewState
    const isMobileViewDimension = useSelector((state: RootState) => state.appStorage.isMobileViewDimension)
    const activeNode = useSelector((state: RootState) => state.nodeModal.value)
    const activeContainerNode = useSelector((state: RootState) => state.activeContainerNode.activeContainerNode);
    const showHiddenBlocks = useSelector((state: RootState) => state.appStorage.showHiddenBlocks);
    const newNode = useSelector((state: RootState) => state.appStorage.newNode)
    const isMobileMovement = useSelector((state: RootState) => state.movement.isMobile)
    // const shoppingCart = useSelector((state: RootState) => state.shoppingModal.value)

    const [div, setDiv] = useState(document.querySelector(`#blockContent${nodeToShow.id}`))

    useEffect(() => {
        if (div === null) {
            setDiv(document.querySelector(`#blockContent${nodeToShow.id}`))
        }
    }, [div, nodeToShow])

    useEffect(() => {
        if (newNode && nodeToShow.id === newNode.id) {
            document.querySelector(`#blockContent${nodeToShow.id}`).scrollIntoView({behavior: 'smooth', block: 'start'})
            dispatch(changeNewNode(null))
        }
    }, [newNode])

    const nodeAttributes = computeAttributes(nodeToShow);
    appendClasses();
    let nodeStyle = computeStyle();

    const dispatch = useDispatch();


    function getBorder() {
        let borderStyle = '';
        if (!previewState && !(nodeToShow.isHidden && !isAdvancedMode) && nodeToShow.id !== '1') {
            if (activeNode && nodeToShow.id === activeNode.id) {
                borderStyle = 'solid-blue'
            } else if (activeContainerNode && nodeToShow.id === activeContainerNode.id) {
                borderStyle = 'dashed-blue'
            } else {
                borderStyle = 'dashed-grey'
            }
        }
        return borderStyle;
    }

    function computeAttributes(nodeToShow: PageNode) {
        return nodeToShow.attributes ?? {};
    }

    function computeStyle() {
        if (nodeToShow.style === undefined) {
            nodeToShow.style = {};
        }
        if (nodeToShow.background) {
            nodeToShow.style = {...nodeToShow.style, ...{background: nodeToShow.background}}
        }


        let textDecorationStyles = {}
        if (nodeToShow.tagName === 'a' && nodeToShow.backClassesIndexed && nodeToShow.backClassesIndexed.textUnderline && nodeToShow.backClassesIndexed.textStrikethrough) {
            textDecorationStyles = {'text-decoration': 'underline line-through'}
        }

        let backgroundStyles
        if (nodeToShow.customStyle || nodeToShow.customStyleMobile) {
            if (nodeToShow.customStyleMobile && nodeToShow.customStyleMobile.backgroundColor && isMobileViewDimension) {
                backgroundStyles = {backgroundColor: '', background: nodeToShow.customStyleMobile.backgroundColor}
            } else if (nodeToShow.customStyle && nodeToShow.customStyle.backgroundColor) {
                backgroundStyles = {backgroundColor: '', background: nodeToShow.customStyle.backgroundColor}
            }
        }

        let borderStyles;
        if(nodeToShow?.customStyle?.borderColor) {
            borderStyles = {borderColor: nodeToShow.customStyle.borderColor}
        }

        if(!isMobileViewDimension && nodeToShow?.customStyleMobile?.order){
            nodeToShow.customStyleMobile = {...nodeToShow.customStyleMobile, order: isMobileMovement ? nodeToShow.customStyleMobile.order : "unset"}
        }

        return {...textDecorationStyles, ...nodeToShow.style, ...nodeToShow.customStyle, ...backgroundStyles, ...borderStyles};
    }


    function appendClasses() {
        const borderStyle = getBorder();

        if (nodeToShow.classes) {
            nodeToShow.classes.forEach(function (element) {
                classes.push(element)
            });
        }
        if (additionalClasses) {
            additionalClasses.forEach(function (element) {
                classes.push(element)
            });
        }


        // if (!selfClosingTagsArray.includes(nodeToShow.tagName) && nodeToShow.id !== '1') {
        //     if (div && !div.childNodes.length && nodeToShow.nodes.length === 0 && (nodeToShow.tagName === 'button' || nodeToShow.className === 'btn')) {
        //         classes = [...classes, 'empty-element']
        //     }
        // }

        if (previewState) {
            classes = classes.filter(el => el !== 'empty-element')
        }

        if (nodeToShow.backClassesMobile) {
            Object.entries(nodeToShow.backClassesMobile).forEach(([key, value]) => {
                if (key === BackClassesField.ColSize) {
                } else {
                    classes.push(value)
                }
            })
        }
        if (nodeToShow.backClassesIndexed) {
            if (nodeToShow.tagName === 'a' && ((!nodeToShow.backClassesIndexed.textUnderline && !nodeToShow.backClassesIndexed.textStrikethrough) || Array.isArray(nodeToShow))) {
                classes.push('text-decoration-none')
            }
            Object.entries(nodeToShow.backClassesIndexed).forEach(([, value]) => {
                if (nodeToShow.tagName === 'a' && nodeToShow.backClassesIndexed.textUnderline && nodeToShow.backClassesIndexed.textStrikethrough) {
                    if (value !== 'text-decoration-underline' && value !== "text-decoration-line-through") {
                        classes.push(value)
                    }
                } else {
                    classes.push(value)
                }
            })
            if (nodeToShow.tagName === 'img' && nodeToShow.backClassesIndexed['imgFluid']) {
                classes.push('w-100')
            }
        } else {
            if (nodeToShow.tagName === 'a') {
                classes.push('text-decoration-none')
            }
        }


        if (nodeToShow.className) {
            classes.push(nodeToShow.className);
        }

        if (nodeToShow.hidden === 'desktopHidden') {
            if (previewState || !showHiddenBlocks) {
                classes.push("d-block");
                classes.push('d-sm-none')
            }
            classes.push("hidden-opacity-desktop");
        }

        if (borderStyle) {
            classes.push(borderStyle)
        }

        if (nodeToShow.hidden === 'mobileHidden') {
            if (previewState || !showHiddenBlocks) {
                classes.push("d-none");
                classes.push('d-sm-block')
            }
            classes.push("hidden-opacity-mobile");
        }

        if (classes) {
            if (classes.length > 0) {
                className = classes.join(' ');
            }
        }
    }


    function handleClick(event: any) {
        if (!previewState) {
            event.preventDefault()
        }
        if (isAdvancedMode || !nodeToShow.isHidden) {
            event.stopPropagation();
            if (nodeToShow.nodes.length !== 0 && nodeToShow.className === 'row' && !isAdvancedMode) {
                return false;
            }
            if (nodeToShow.id === '1') {
                return false;
            }
            dispatch(changeActiveModalNode(nodeToShow));
            const parentNode = findNodeWhichAreContainer(nodeToShow, nodes[0], 'row');
            dispatch(changeActiveContainerNode(parentNode));
        }


    }

    function handleDoubleClick(event: any) {
        event.stopPropagation();
        Modal.getOrCreateInstance('#edit-block-modal').show();
    }

    // function openShoppingModalByClick(e) {
    //     e.stopPropagation()
    //     Modal.getOrCreateInstance('#shopping-cart-modal').show()
    //     const oldShoppingCart = shoppingCart.find(el => el.productName === nodeToShow.productName)
    //     const quantity = oldShoppingCart && oldShoppingCart.productQuantity !== '' ? String(Number(oldShoppingCart.productQuantity) + 1) : '1'
    //
    //     const newShoppingCart = updateProductInCookie({
    //         productName: nodeToShow.productName,
    //         productPrice: nodeToShow.productPrice,
    //         productSrc: nodeToShow.src,
    //         productQuantity: quantity
    //     })
    //     dispatch(setShoppingCart(newShoppingCart));
    // }


    function getInnerValue(nodeToShow: PageNode) {
        if (nodeToShow.value) {
            return nodeToShow.value
        }
        if (nodeToShow.tagName === 'div') {
            if (nodeToShow.nodes.length === 0 && nodeToShow.id !== '1' && !previewState) {
                return <>
                    <div
                        className='text-center text-background-mobile fs-7 d-flex align-items-center h-100 justify-content-center'>
                        <span onClick={(e) => {
                            e.stopPropagation();
                            dispatch(changeActiveModalNode(nodeToShow));
                            Modal.getOrCreateInstance('#container-to-add-modal').show();
                        }}><i className='bi bi-plus-lg'></i>Add Content</span>
                    </div>
                </>;
            }
        }
        const div = document.querySelector(`#blockContent${nodeToShow.id}`)
        if (div && !(div.clientHeight && div.clientWidth)) {
            return ''
        }
        // else if (!nodeToShow.value && nodeToShow.nodes.length === 0 && ['p', 'span', 'a', 'li', 'text'].indexOf(nodeToShow.tagName) !== -1 && !previewState) {
        //     // if (div && (!div.clientHeight || !div.clientWidth)) {
        //     //     nodeToShow.classes = [...classes, 'empty-element']
        //     //     return
        //     // }
        //     // return 'Add your custom text';
        // } else if (nodeToShow.nodes.length === 0 && nodeToShow.isCustomTag) {
        //     // if (div && (!div.clientHeight || !div.clientWidth)) {
        //     //     nodeToShow.classes = [...classes, 'empty-element']
        //     //     return ''
        //     // } else {
        //     //     return nodeToShow.tagName;
        //     // }
        //
        // }
        return nodeToShow.value;
    }

    return <>
        {(isAdvancedMode && nodeToShow.tagName === 'button' && nodeToShow.className === 'navbar-toggler') &&
            <Dropdown nodeToShow="navbar-toggler"/>}
        {(() => {
                if (nodeToShow.tagName === 'text')
                    return <OnClickActionWrapper nodeToShow={nodeToShow}>
                        <TextBlock
                        styleObject={nodeStyle}
                        nodeToShow={nodeToShow}
                        isPreviewState={previewState}
                        className={(className + " text-break")}
                        isMobileViewDimension={isMobileViewDimension}
                        handleClick={handleClick}
                        handleDoubleClick={handleDoubleClick}
                        />
                    </OnClickActionWrapper>
                else if (nodeToShow.tagName === 'img')
                    return <OnClickActionWrapper nodeToShow={nodeToShow}>
                        <ImageBlock
                            styleObject={nodeStyle}
                            nodeToShow={nodeToShow}
                            classString={className}
                            isMobileViewDimension={isMobileViewDimension}
                            handleClick={handleClick}
                            handleDoubleClick={handleDoubleClick}/>
                    </OnClickActionWrapper>
                else if (nodeToShow.type === NodeType.ButtonNode)
                    return <ButtonBlock
                        className={className + " text-break"}
                        handleClick={handleClick}
                        handleDoubleClick={handleDoubleClick}
                        nodeToShow={nodeToShow as ButtonNode}
                        isMobileViewDimension={isMobileViewDimension}
                        styleObject={nodeStyle}
                        />
                else if (node.type === NodeType.IFrameNode) {
                    return (<IFrameComponent
                        nodes={nodes[0]}
                        key={node.id}
                        handleClick={handleClick}
                        handleDoubleClick={handleDoubleClick}
                        node={node}/>)
                } else if (node.type === NodeType.Accordion) {
                    return (<AccordionElement
                        key={node.id}
                        handleClick={handleClick}
                        handleDoubleClick={handleDoubleClick}
                        node={node}
                        styleObject={nodeStyle}
                        getInnerValue={getInnerValue}
                        />
                    )
                }
                    // else if (nodeToShow.tagName === 'div' && nodeToShow.editSettings && (nodeToShow.editSettings.isSocialLinks === true || nodeToShow.editSettings.isSocialTextLinks === true))
                    //     return <SocialLinks
                    //             styleObject={nodeStyle}
                    //             nodeToShow={nodeToShow}
                    //             classString={className}
                    //             isMobileViewDimension={isMobileViewDimension}
                    //             handleClick={handleClick}
                    //             handleDoubleClick={handleDoubleClick}
                //     />
                else if (nodeToShow.type === NodeType.IconNode)
                    return <OnClickActionWrapper nodeToShow={nodeToShow}>
                        <IconBlock classString={className} handleClick={handleClick}
                                   handleDoubleClick={handleDoubleClick}
                                   isMobileViewDimension={isMobileViewDimension}
                                   nodeAttributes={nodeAttributes}
                                   nodeToShow={nodeToShow}
                                   styleObject={nodeStyle}
                        />
                    </OnClickActionWrapper>
                else if (selfClosingTagsArray.includes(nodeToShow.tagName))
                    return <OnClickActionWrapper nodeToShow={nodeToShow}><DynamicElement
                        node={nodeToShow}
                        onClick={handleClick}
                        onDoubleClick={handleDoubleClick}
                        id={'blockContent' + nodeToShow.id}
                        className={className}
                        style={nodeStyle}
                    /></OnClickActionWrapper>
                else {
                    return <OnClickActionWrapper nodeToShow={nodeToShow}>
                        <DynamicElement
                                           node={nodeToShow}
                                    {...nodeAttributes}
                                    onClick={handleClick}
                                    onDoubleClick={handleDoubleClick}
                                    id={'blockContent' + nodeToShow.id}
                                    className={(className)}
                                    style={nodeStyle}
                    >
                        {getInnerValue(nodeToShow)}
                        {(nodeToShow.nodes !== undefined && nodeToShow.nodes.length > 0) ? nodeToShow.nodes.map((childNode) => {
                            if (childNode) if (childNode.type === NodeType.DivColNode) {
                                return <OnClickActionWrapper nodeToShow={childNode} key={childNode.id}>
                                    <DivColNodeComponent
                                        isPreviewState={isPreviewState}
                                        node={childNode as DivColNode}/></OnClickActionWrapper>
                            } else if (childNode.type === NodeType.IFrameNode) {
                                return <IFrameComponent nodes={nodes[0]} key={childNode.id}
                                                        handleClick={handleClick}
                                                        handleDoubleClick={handleDoubleClick}
                                                        node={childNode}/>
                            } else {
                                return (<Node isPreviewState={isPreviewState} key={childNode.id} node={childNode}
                                              idPrefix={idPrefix}/>);
                            }
                            return '';
                        }) : ''}
                    </DynamicElement>
                    </OnClickActionWrapper>
                }
        })()}
        <CustomStyles node={node} isMobileViewDimension={isMobileViewDimension}/>
    </>;

}

export default Node
