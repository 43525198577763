import React, {useEffect, useRef, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {ChangeNodeFunction,} from "../../../service/editModalService";
import {MobileStyleRadioButton} from "../ui/MobileStyleRadioButton";
import {PageNode} from "../../../types/PageNode";
import {UserRole} from "../../../types/User";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import ColorPicker from "react-best-gradient-color-picker";
import { extractInlineStylesToClasses, mergeInlineStylesWithClasses, parseHTMLString, updateElementClasses } from "../helpers";

interface TinyTextInputProperty {
    editableNode: PageNode,
    inline?: boolean,
    menubar?: boolean,
    isMobileViewDimension: boolean,
    changeNodeFunction: ChangeNodeFunction,
}
 
export const TinyTextInput: React.FC<TinyTextInputProperty> = ({
                                                                   editableNode,
                                                                   changeNodeFunction,
                                                                   inline = false,
                                                                   menubar = true,
                                                                   isMobileViewDimension,
                                                               }) => {
    // ЭТУ БАЛДУ НЕЛЬЗЯ РЕРЕНДЕРИТЬ ТАК КАК СБИВАЕТСЯ КУРСОР ПОСЛЕ НАЖАТИЯ ЕНТЕР!!!!
    const editorRef = useRef(null);

    const user = useSelector((state: RootState) => state.appStorage.user)
    const mainNode: PageNode = useSelector((state: RootState) => state.nodes.present.value[0]);

    const [editorKey, setEditorKey] = useState(Date.now());
    const nodeModalValue: PageNode = useSelector((state: RootState) => state.nodeModal.value);
    const [textValue, setTextValue] = useState("");

    let isAdmin: boolean = false;
    if (user) {
        isAdmin = user.roles.includes(UserRole.ROLE_ADMIN)
    }
    

    useEffect(() => {
        setEditorKey(Date.now());
    }, [mainNode, editableNode.isMobileTextEnabled])
    
    useEffect(() => {
        document.addEventListener("focusin", function (e: FocusEvent) {
            const target = e.target as HTMLElement
            if (
                target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !==
                null
            ) {
                e.stopImmediatePropagation();
            }
        });
    }, []);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const button = document.querySelector('button[title="Reveal or hide additional toolbar items"]') as HTMLButtonElement;
            if (button && !button.contains(event.target as Node) && button.getAttribute('aria-expanded') === 'true') {
                // Close popup "..." if click outside button
                button.click();
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    let useMobileText: boolean = false
    let isShowMobileTextEnabler = false
    if (isMobileViewDimension && isAdmin) {
        //TODO: tmp fix make, Common user at me qr see this.
        isShowMobileTextEnabler = true
        useMobileText = editableNode.isMobileTextEnabled ?? false
    }

    const updateValues = () => {
        const editorContent = editorRef.current.getContent({ format: "html" });

        if (useMobileText) {
            const { updatedHTML: newHTMLText, styleMap: additionalStyles } = 
                updateElementClasses(editorContent, nodeModalValue.textMobileClasses);
            const doc = parseHTMLString(newHTMLText);
            const { updatedHTML, styleMap } = 
                mergeInlineStylesWithClasses(doc, { ...nodeModalValue.textMobileClasses, ...additionalStyles });

            editableNode.textMobileClasses = styleMap;
            editableNode.textClasses = { ...nodeModalValue.textClasses };
            editableNode.value = updatedHTML;
            editableNode.isMobileTextEnabled = true;
        } else {
            const { updatedHTML: newHTMLText, styleMap: additionalStyles } = 
                updateElementClasses(editorContent, nodeModalValue.textClasses);
            const doc = parseHTMLString(newHTMLText);
            const { updatedHTML, styleMap } = 
                mergeInlineStylesWithClasses(doc, { ...nodeModalValue.textClasses, ...additionalStyles });

            editableNode.textClasses = styleMap;
            editableNode.textMobileClasses = { ...nodeModalValue.textMobileClasses };
            editableNode.value = updatedHTML;
            editableNode.isMobileTextEnabled = false;
        }

        changeNodeFunction(editableNode);
    };

    // extract inline styles to desktop and mobile classes
    useEffect(() => {
        const doc = parseHTMLString(editableNode.value || "");
        const { styleMap, updatedHTML } = extractInlineStylesToClasses(doc);

        setTextValue(updatedHTML);

        if (useMobileText && editableNode.textMobileClasses && Object.keys(editableNode.textMobileClasses).length > 0) {
            editableNode.textMobileClasses = styleMap;
        } else {
            editableNode.textClasses = styleMap;
        }
    }, [useMobileText, editableNode]);


    // if (value === undefined)
    //     value = ''

    //TODO: https://www.tiny.cloud/docs/tinymce/latest/react-pm-host/

  const [saveEditor, setSaveEditor] = useState(null)
  const [gradient, setGradient] = useState("");
  const [isActiveGradient, setIsActiveGradient] = useState(false);
  const handleChangeColor = (color: string): void => {
    setGradient(color);
  };

  const toggleGradientPicker = (status: boolean, editor?: any) => {
    if(editor) setSaveEditor(editor)

    const dropdown = document.querySelector('.tox-toolbar__overflow') as HTMLElement;
    if(dropdown) dropdown.style.visibility = 'hidden';

    setIsActiveGradient(status);
  };

  const applyGradient = (editor: any, gradient: string) => {
    const selectedContent = editor.selection.getContent({ format: "html" });
    const selectedNode = editor.selection.getNode();
    const computedStyle = window.getComputedStyle(selectedNode);

    const fontSize = computedStyle.fontSize;
    const fontWeight = computedStyle.fontWeight;

    const span = document.createElement("span");
    span.innerHTML = selectedContent;

    const content = `<span style="background: ${gradient}; -webkit-background-clip: text; color: transparent; font-size: ${fontSize}; font-weight: ${fontWeight};">${editor.selection.getContent()}</span>`;
    editor.execCommand("mceInsertContent", false, content);
  };

  const elements = document.querySelectorAll(".tox-collection__item-label")
  if(elements){
    elements.forEach((elem) => elem.removeAttribute("style"))
  }

    return (
        <>
            <div className="mb-3" style={{height: `${isActiveGradient ? "700px" : ""}`}}>
                <Editor
                    key={editorKey}
                    tinymceScriptSrc={'/tinymce/tinymce.min.js'}
                    licenseKey="gpl"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={
                        textValue
                    }
                    init={{
                        height: 500,
                        menubar: menubar,
                        inline: inline,
                        mobile: {
                            menubar: menubar,
                            toolbar_mode: 'sliding',  
                        },
                        // forced_root_block_attrs: {
                        //     'class': 'p-0 m-0',
                        // },
                        // selector: selector,
                        font_size_formats:
                            "8px 10px 12px 14px 16px 18px 20px 22px 24px 28px 30px 32px 34px 36px 40px 42px 44px 46px 48px 56px",
                        line_height_formats: "1 1.1 1.2 1.3 1.4 1.5 1.6 1.8 2",
                        plugins: [
                            "advlist",
                            "fullscreen",
                            "lists",
                            "link",
                            "table",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic underline | forecolor | gradientPicker | fontselect fontsize |  alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | link|" +
                            "removeformat | help",
                        content_style: `
                            body{
                                background-color: #CDD1D7;
                                font-size: 14px;
                                    span{
                                        font-size: ${mainNode?.customStyle?.fontSize || "14px"};
                                        ${mainNode?.customStyle?.color ? `color: ${mainNode.customStyle.color};` : ""}
                                    }
                            }
                            ${nodeModalValue.textClasses ? Object.entries(nodeModalValue.textClasses).map(([key, value]) => {
                                return `.${key}{
                                        ${value}
                                    }`
                            }).join('') : ""}
                             ${nodeModalValue.textMobileClasses && isShowMobileTextEnabler && useMobileText ? Object.entries(nodeModalValue.textMobileClasses).map(([key, value]) => {
                                return `.${key}{
                                        ${value}
                                    }`
                            }).join('') : ""}
                        `,
                        setup: (editor) => {
                            editor.on('init', function () {
                                const editedText = editor.getBody().querySelector("p")
                                const text = editor.getBody().querySelector("span")
                                if(editedText?.style) editedText.style.fontFamily = mainNode?.customStyle?.fontFamily;
                                if(text?.style && !text?.style?.fontFamily) text.style.fontFamily = mainNode?.customStyle?.fontFamily;

                            });
                            editor.on('PostRender', () => {
                                const menubar = editor.getContainer().querySelector('.tox-menubar') as HTMLElement;
                                if (menubar) {
                                    menubar.style.flexWrap = 'nowrap';
                                    menubar.style.overflow = 'auto';

                                    const styleSheet = document.createElement('style');
                                    styleSheet.type = 'text/css';
                                    styleSheet.innerText = `
                                        .tox-menubar {
                                            -ms-overflow-style: none;
                                            scrollbar-width: none;
                                        }
                                        .tox-menubar::-webkit-scrollbar {
                                            display: none;
                                        }
                                    `;
                                    document.head.appendChild(styleSheet);
                                }
                            });    
                            editor.ui.registry.addButton("gradientPicker", {
                              text: "Color",
                              onAction: () => toggleGradientPicker(!isActiveGradient, editor),
                            });
                        },
                    }}
                    onBlur={() => {
                        updateValues();
                    }}
                />
                <div className={`${ isActiveGradient ? "d-block" : "d-none"} position-absolute shadow bg-white rounded`} style={{top: "0", right: "30px", zIndex: "2", padding: "20px"}}>
                        <div className="mb-3 d-flex align-items-center justify-content-between">
                            <div>
                                <button type="button" className="btn btn-close pt-3" onClick={() => toggleGradientPicker(false)}></button>
                            </div>
                        </div>
                    <ColorPicker className={'tmp tinymce-gradient-picker'} value={gradient} onChange={handleChangeColor}/>
                    <div className="text-end">
                        <button type="button" className="btn btn-primary w-100 mt-3" onClick={() => {
                            if (saveEditor && gradient) {
                                applyGradient(saveEditor, gradient);
                                toggleGradientPicker(false);
                            }
                        }}>Select</button>
                    </div>
                </div>
            </div>
            <div className={'d-flex'}>

                {isShowMobileTextEnabler ? <><h5 className={'mb-3 me-auto'}>Save to mobile version</h5>
                    <MobileStyleRadioButton currentStatus={editableNode.isMobileTextEnabled ?? false}
                                            onChange={(state) => {
                                                editableNode.textClasses = { ...nodeModalValue.textClasses };
                                                editableNode.textMobileClasses = { ...nodeModalValue.textMobileClasses };
                                                editableNode.isMobileTextEnabled = state;
                                                changeNodeFunction(editableNode)
                                            }}/></> : ""}

            </div>
        </>
    );
};

export default TinyTextInput;
