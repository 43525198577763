import React from 'react';
import {PageNode} from "../../../types/PageNode";

interface TextBlockProperty {
    nodeToShow: PageNode,
    styleObject: any,
    handleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    isMobileViewDimension: boolean,
    className: string,
    isPreviewState: boolean,
    handleDoubleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const TextBlock: React.FC<TextBlockProperty> = ({
                                                           nodeToShow,
                                                           styleObject,
                                                           handleClick,
                                                           className,
                                                           handleDoubleClick,
                                                           isPreviewState
                                                       }) => {
    let pcValue: string
    if (isPreviewState) {
        pcValue = nodeToShow.value ?? ''
    } else {
        pcValue = nodeToShow.value ?? 'Add your custom text'
    }

    const generateMobileStyles = (): string => {
        if (
            !nodeToShow.isMobileTextEnabled || 
            !nodeToShow.textMobileClasses || 
            Object.keys(nodeToShow.textMobileClasses).length === 0
        ) {
            return '';
        }
        return `
            @media (max-width: 576px) {
                ${Object.entries(nodeToShow.textMobileClasses)
                    .map(
                        ([key, value]) =>
                            `#blockContent${nodeToShow.id} .${key} { ${value } }`
                    )
                    .join('\n')}
            }
        `;
    };

    const generateStyles = (): string => {
        if ( 
            !nodeToShow.textClasses || 
            Object.keys(nodeToShow.textClasses).length === 0
        ) {
            return '';
        }
        return `
                ${Object.entries(nodeToShow.textClasses)
                    .map(
                        ([key, value]) =>
                            `#blockContent${nodeToShow.id} .${key} { ${value} }`
                    )
                    .join('\n')}
        `;
    };

    const styles = generateStyles();
    const mobileStyles = generateMobileStyles();

    return (
        <>  
            {(styles || mobileStyles) ? <style>{styles} {mobileStyles}</style> : ''}
            <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                id={`blockContent${nodeToShow.id}`}
                className={`${className} d-block`}
                style={styleObject}
                dangerouslySetInnerHTML={{ __html: pcValue }}
            ></div>
        </>
    )
};

export default TextBlock;
