import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeIsMobileDisplayMode,} from "../../reducers/app";
import {ActionCreators} from "redux-undo";
import {Modal} from "bootstrap";
import {changeNode, deleteNode, setNodes} from "../../reducers/nodes";
import {changeActiveModalNode} from "../../reducers/node-modal";
import ExportLoad from "../system/ExportLoad";
import {handlerCopy, pasteCopyNode} from "../../service/EditBarService";
import PublishPageState from "../system/PublishPageState";
import {useAdditionalHandlers, useClickHandlers, useDropdown} from "../../service/desktopNavigationService";
import {openModal} from "../../reducers/modalSlice";
import {ServiceContainer} from "../../service/ServiceContainer";
import QueryService from "../../service/QueryService";
import { useGetMeQuery } from '../../service/User/UserService.hooks';
import { UserRole } from '../../types/User';
import { setMovementStatus } from "../../reducers/movementSlice";
import { useGetPageLazyQuery } from "../../service/Page/PageService.hooks";

const DesktopNavigationBar = ({isMobileDisplayMode, isMobileViewDimension, getMetaTag, pageTitle}) => {
    const isExportState = useSelector((state) => state.appStorage.isExportState)
    const isPublishPageState = useSelector((state) => state.appStorage.isPublishPageState)
    const previewState = useSelector((state) => state.appStorage.preview)
    const isAdvancedMode = useSelector((state) => state.appStorage.isAdvancedMode)
    const countUndoRedo = useSelector((state) => state.nodes)
    const activeNode = useSelector((state) => state.nodeModal.value)
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.nodes.present.value);
    const showHiddenBlocks = useSelector((state) => state.appStorage.showHiddenBlocks);
    const isMobileMovement = useSelector((state) => state.movement.isMobile)
    const pageId = useSelector((state) => state.appStorage.pageId);
    const {dropdownRef, isDropdownOpen, handleDropdownToggle} = useDropdown();
    const {handlerClickExport, handlerClickPublish, handleClickDuplicate} = useClickHandlers(dispatch);
    const {
        handlePlayClick,
        changeIsAdvancedModeAction,
        confirmActionAndRedirect,
        handleDelete,
        handleShowHiddenBlocksClick
    } = useAdditionalHandlers(dispatch, previewState, nodes);

    const isMeQrMenu = ServiceContainer.resolve('QueryService').token
    const {data: user, loading: userLoading} = useGetMeQuery()
    const [getPage] = useGetPageLazyQuery({
        variables: {id: pageId}, 
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const contentString = data.page.contentString;
            if (contentString && JSON.parse(contentString).length > 0) {
                dispatch(setNodes({nodes: JSON.parse(contentString)}));
            }
        },
    })

    const changeMovementStatus = (state) => {
        if(state === isMobileMovement) return;
        dispatch(setMovementStatus({isMobile: state.target.checked}))
        dispatch(changeActiveModalNode(null))
    }

    return (<>
            <nav className="navbar first-navbar-menu navbar-expand-lg  fixed-top">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center d-lg-flex d-none"
                         id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0 col-4">
                            <li className="nav-item dropdown" ref={dropdownRef}>
                                {!isMeQrMenu &&
                                    <span className="nav-link " role="button" data-bs-toggle="dropdown"
                                          aria-expanded="false" onClick={handleDropdownToggle}>
                                    <i className="bi bi-list "></i>
                                </span>
                                }
                                <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                                    {!userLoading && isMobileDisplayMode && user.me.roles.includes(UserRole.ROLE_ADMIN) &&
                                    <li className="m-2 ms-4 py-2 d-flex">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox"
                                               onChange={(state) => changeMovementStatus(state)}
                                               checked={isMobileMovement}/>
                                        </div>
                                        <p className='text-white'>Change Only Mobile</p>
                                    </li>}
                                    <li className="m-2"><a className="dropdown-item py-2" href={`${process.env.REACT_APP_NEW_ADMIN}/projects/new`}>
                                        <i className="bi bi-file-earmark me-2"></i>New Page</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href={`${process.env.REACT_APP_NEW_ADMIN}/projects`}>
                                        <i className="bi bi-files me-2"></i>My Projects</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#"
                                                           onClick={handlerClickExport}>
                                        <i className="bi bi-reply me-2"></i>Export</a>
                                    </li>
                                    {/*<li className="m-2"><ExportNode activeNode={activeNode}/></li>*/}
                                    <li className="m-2">
                                        <span className="dropdown-item py-2"
                                              onClick={handlerClickPublish}>
                                            <i className='bi bi-box-arrow-up-right me-2'></i>Publish Page</span>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#" onClick={() => {
                                        dispatch(openModal({modalName: "css-link-modal"}));
                                    }}>
                                        <i className="bi bi-code-slash pe-2"></i> CSS Link</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#">
                                        <i className="bi bi-download pe-2"></i> Save Page</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#"
                                                           onClick={handleClickDuplicate}>
                                        <i className="bi bi-file-earmark-plus pe-2"></i> Duplicate Page</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#"
                                                           onClick={(event) => confirmActionAndRedirect(event)}>
                                        <i className="bi bi-trash pe-2"></i> Delete Page</a>
                                    </li>
                                    <li className="dark-line mx-2"></li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#"
                                                           onClick={handleDelete}>
                                        <i className="bi bi-x-circle pe-2"></i> Clear All Elements</a>
                                    </li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#"
                                                           onClick={handleShowHiddenBlocksClick}>
                                        {showHiddenBlocks ? (
                                                <>
                                                    <i className="bi bi-eye-slash pe-2"></i> Hide Blocks
                                                </>
                                            ) :
                                            (<>
                                                    <i className="bi bi-eye pe-2"></i> Show Hidden Blocks
                                                </>
                                            )}
                                    </a>
                                    </li>
                                    <li className="dark-line mx-2"></li>
                                    <li className="m-2"><a className="dropdown-item py-2" href="#">
                                        <i className="bi bi-question-circle pe-2"></i> Help</a>
                                    </li>
                                    {/*<li className="m-2"><a className="dropdown-item py-2" href="#">*/}
                                    {/*    <i className="bi bi-person me-2"></i> Profile</a>*/}
                                    {/*</li>*/}
                                    <li className="m-2"><a className="dropdown-item py-2" href="/logout">
                                        <i className="bi bi-box-arrow-right pe-2"></i> Log Out</a>
                                    </li>
                                </ul>
                            </li>
                            {!isMobileDisplayMode &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={`px-2 mx-2 d-flex align-items-center fs-5 text-white`}
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Page Settings" type="button"
                                            onClick={() => {
                                                Modal.getOrCreateInstance('#global-settings-modal').show()
                                            }}><i className="bi bi-gear"></i>
                                        </div>
                                    </div>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${countUndoRedo.past.length <= 1 ? "disabled" : ""}`}
                                            onClick={() => {
                                                dispatch(ActionCreators.undo());
                                                dispatch(changeNode({ editedNode: nodes }));
                                            }}>
                                            <i className="bi bi-arrow-left-circle"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={`nav-link ${countUndoRedo.future.length === 0 ? "disabled" : ""}`}
                                            onClick={() => {
                                                dispatch(ActionCreators.redo());
                                                dispatch(changeNode({ editedNode: nodes }));
                                            }}>
                                            <i className="bi bi-arrow-right-circle"></i>
                                        </a>
                                    </li>
                                    <div className="d-flex align-items-center" role="search">
                                        <div
                                            className={`btn btn-primary px-3 mx-2 d-flex align-items-center fw-bold fs-7`}
                                            onClick={() => {
                                                dispatch(changeActiveModalNode(nodes[0]));
                                                Modal.getOrCreateInstance('#container-to-add-modal').show()
                                            }}><i className="bi bi-plus-lg me-2"></i> <span className='text-nowrap'>Create Block</span>
                                        </div>
                                    </div>
                                    <li className="nav-item mx-1">
                                        <span className={`nav-link ${activeNode ? "" : "disabled"}`} onClick={
                                            () => {
                                                Modal.getOrCreateInstance('#edit-block-modal').show()
                                            }
                                        }>
                                            <i className="bi bi-pencil"></i>
                                        </span>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <span className={`nav-link ${activeNode ? "" : "disabled"}`} onClick={() => {
                                            dispatch(deleteNode({removeNode: activeNode}))
                                            dispatch(changeActiveModalNode(undefined));
                                        }}><i className="bi bi-trash"></i>
                                        </span>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <span className={`nav-link   ${activeNode ? "" : "disabled"}`} onClick={() => {
                                            handlerCopy(activeNode)
                                        }}><i className="bi bi-stickies"></i></span>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <span className={`nav-link   ${activeNode ? "" : "disabled"}`} onClick={() => {
                                            dispatch(deleteNode({removeNode: activeNode}))
                                            dispatch(changeActiveModalNode(undefined));
                                            handlerCopy(activeNode)
                                        }}><i className="bi bi-scissors"></i></span>
                                    </li>
                                    <li className="nav-item mx-1">
                                        <span className={`nav-link   ${activeNode ? "" : "disabled"}`} onClick={() => {
                                            pasteCopyNode(dispatch, activeNode)
                                        }}><i className="bi bi-clipboard-check"></i></span>
                                    </li>
                                </>
                            }
                        </ul>
                        <div className="mx-auto d-flex align-items-center">
                            <ul className="navbar-nav">
                                <li className="text-white text-center">
                                    {pageTitle ? pageTitle : "title"}
                                </li>
                            </ul>
                        </div>
                        <ul className="navbar-nav ms-auto pe-3 mb-2 mb-lg-0 d-flex align-items-center col-4 justify-content-end">
                            {/*<li className=" pe-3 text-size-gray-text">{zoom}%</li>*/}
                            {!isMeQrMenu && <>
                                <li className={`nav-item ${isMobileDisplayMode === false ? 'active' : 'no'}`}>
                                    <a className="nav-link"
                                       onClick={async () => {
                                            await dispatch(changeIsMobileDisplayMode(false));
                                            getPage();
                                       }}>
                                        <i className="bi bi-display"></i>
                                    </a>
                                </li>
                                <li className={`nav-item ${isMobileDisplayMode === true ? 'active' : ''}`}>
                                    <a className="nav-link"
                                       onClick={() => {
                                           dispatch(changeActiveModalNode(null))
                                           dispatch(changeIsMobileDisplayMode(true))
                                           getMetaTag.setAttribute("content", "width=device-width, initial-scale=1")
                                       }}>
                                        <i className="bi bi-phone"></i>
                                    </a>
                                </li>
                            </>
                            }
                            <li className={`nav-item px-2 ${previewState ? 'active' : ''}`}>
                                <a className={`nav-link`}
                                   onClick={handlePlayClick}>
                                    <i className="bi bi-play-circle"></i>
                                </a>
                            </li>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox"
                                       onChange={() => {
                                           changeIsAdvancedModeAction(!isAdvancedMode)
                                       }}
                                       checked={isAdvancedMode}
                                       id="flexSwitchCheckDefault"/>
                                <label className="form-check-label text-white" htmlFor="flexSwitchCheckDefault">Advanced
                                    Mode</label>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
            {isExportState &&
                <ExportLoad isExportState={isExportState}/>}
            {isPublishPageState &&
                <PublishPageState/>}
            <div className={'top-fake-block w-100'}></div>
            {
                activeNode !== null && isMobileViewDimension &&
                <div className={'top-fake-block w-100'}></div>
            }

        </>
    )

}

export default DesktopNavigationBar
