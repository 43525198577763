import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMobile: false,
}

const movementSlice = createSlice({
    name: "movement",
    initialState,
    reducers: {
        setMovementStatus: (state, action) => {
            state.isMobile = action.payload.isMobile;
        },
    },
});

export const { setMovementStatus } = movementSlice.actions;
export default movementSlice.reducer;